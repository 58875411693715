/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomUser,
  Login,
  PasswordChange,
  PasswordReset,
  PasswordResetConfirm,
  PasswordlessRegistration,
  PatchedCustomUser,
  ResendEmailVerification,
  RestAuthDetail,
  Token,
  VerifyEmail,
} from '../models/index';
import {
    CustomUserFromJSON,
    CustomUserToJSON,
    LoginFromJSON,
    LoginToJSON,
    PasswordChangeFromJSON,
    PasswordChangeToJSON,
    PasswordResetFromJSON,
    PasswordResetToJSON,
    PasswordResetConfirmFromJSON,
    PasswordResetConfirmToJSON,
    PasswordlessRegistrationFromJSON,
    PasswordlessRegistrationToJSON,
    PatchedCustomUserFromJSON,
    PatchedCustomUserToJSON,
    ResendEmailVerificationFromJSON,
    ResendEmailVerificationToJSON,
    RestAuthDetailFromJSON,
    RestAuthDetailToJSON,
    TokenFromJSON,
    TokenToJSON,
    VerifyEmailFromJSON,
    VerifyEmailToJSON,
} from '../models/index';

export interface DjRestAuthLoginCreateRequest {
    login: Login;
}

export interface DjRestAuthPasswordChangeCreateRequest {
    passwordChange: PasswordChange;
}

export interface DjRestAuthPasswordResetConfirmCreateRequest {
    passwordResetConfirm: PasswordResetConfirm;
}

export interface DjRestAuthPasswordResetCreateRequest {
    passwordReset: PasswordReset;
}

export interface DjRestAuthRegistrationCreateRequest {
    passwordlessRegistration: PasswordlessRegistration;
}

export interface DjRestAuthRegistrationResendEmailCreateRequest {
    resendEmailVerification: ResendEmailVerification;
}

export interface DjRestAuthRegistrationVerifyEmailCreateRequest {
    verifyEmail: VerifyEmail;
}

export interface DjRestAuthUserPartialUpdateRequest {
    patchedCustomUser?: Omit<PatchedCustomUser, 'pk'|'email'|'uuid_from_email'|'teams'>;
}

export interface DjRestAuthUserUpdateRequest {
    customUser: Omit<CustomUser, 'pk'|'email'|'uuid_from_email'|'teams'>;
}

/**
 * DjRestAuthApi - interface
 * 
 * @export
 * @interface DjRestAuthApiInterface
 */
export interface DjRestAuthApiInterface {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthLoginCreateRaw(requestParameters: DjRestAuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>>;

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    djRestAuthLoginCreate(requestParameters: DjRestAuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token>;

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    djRestAuthLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthLogoutRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    djRestAuthLogoutRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthPasswordChangeCreateRaw(requestParameters: DjRestAuthPasswordChangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     */
    djRestAuthPasswordChangeCreate(requestParameters: DjRestAuthPasswordChangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthPasswordResetConfirmCreateRaw(requestParameters: DjRestAuthPasswordResetConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     */
    djRestAuthPasswordResetConfirmCreate(requestParameters: DjRestAuthPasswordResetConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordReset} passwordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthPasswordResetCreateRaw(requestParameters: DjRestAuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     */
    djRestAuthPasswordResetCreate(requestParameters: DjRestAuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * 
     * @param {PasswordlessRegistration} passwordlessRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthRegistrationCreateRaw(requestParameters: DjRestAuthRegistrationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>>;

    /**
     */
    djRestAuthRegistrationCreate(requestParameters: DjRestAuthRegistrationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token>;

    /**
     * 
     * @param {ResendEmailVerification} resendEmailVerification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthRegistrationResendEmailCreateRaw(requestParameters: DjRestAuthRegistrationResendEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     */
    djRestAuthRegistrationResendEmailCreate(requestParameters: DjRestAuthRegistrationResendEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * 
     * @param {VerifyEmail} verifyEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthRegistrationVerifyEmailCreateRaw(requestParameters: DjRestAuthRegistrationVerifyEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>>;

    /**
     */
    djRestAuthRegistrationVerifyEmailCreate(requestParameters: DjRestAuthRegistrationVerifyEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedCustomUser} [patchedCustomUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthUserPartialUpdateRaw(requestParameters: DjRestAuthUserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    djRestAuthUserPartialUpdate(requestParameters: DjRestAuthUserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthUserRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    djRestAuthUserRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {CustomUser} customUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DjRestAuthApiInterface
     */
    djRestAuthUserUpdateRaw(requestParameters: DjRestAuthUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>>;

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    djRestAuthUserUpdate(requestParameters: DjRestAuthUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser>;

}

/**
 * 
 */
export class DjRestAuthApi extends runtime.BaseAPI implements DjRestAuthApiInterface {

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async djRestAuthLoginCreateRaw(requestParameters: DjRestAuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['login'] == null) {
            throw new runtime.RequiredError(
                'login',
                'Required parameter "login" was null or undefined when calling djRestAuthLoginCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginToJSON(requestParameters['login']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async djRestAuthLoginCreate(requestParameters: DjRestAuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.djRestAuthLoginCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    async djRestAuthLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    async djRestAuthLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthLogoutCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    async djRestAuthLogoutRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/logout/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    async djRestAuthLogoutRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthLogoutRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     */
    async djRestAuthPasswordChangeCreateRaw(requestParameters: DjRestAuthPasswordChangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters['passwordChange'] == null) {
            throw new runtime.RequiredError(
                'passwordChange',
                'Required parameter "passwordChange" was null or undefined when calling djRestAuthPasswordChangeCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/password/change/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeToJSON(requestParameters['passwordChange']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     */
    async djRestAuthPasswordChangeCreate(requestParameters: DjRestAuthPasswordChangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthPasswordChangeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     */
    async djRestAuthPasswordResetConfirmCreateRaw(requestParameters: DjRestAuthPasswordResetConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters['passwordResetConfirm'] == null) {
            throw new runtime.RequiredError(
                'passwordResetConfirm',
                'Required parameter "passwordResetConfirm" was null or undefined when calling djRestAuthPasswordResetConfirmCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/password/reset/confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetConfirmToJSON(requestParameters['passwordResetConfirm']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     */
    async djRestAuthPasswordResetConfirmCreate(requestParameters: DjRestAuthPasswordResetConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthPasswordResetConfirmCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     */
    async djRestAuthPasswordResetCreateRaw(requestParameters: DjRestAuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters['passwordReset'] == null) {
            throw new runtime.RequiredError(
                'passwordReset',
                'Required parameter "passwordReset" was null or undefined when calling djRestAuthPasswordResetCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/password/reset/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetToJSON(requestParameters['passwordReset']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     */
    async djRestAuthPasswordResetCreate(requestParameters: DjRestAuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthPasswordResetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async djRestAuthRegistrationCreateRaw(requestParameters: DjRestAuthRegistrationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['passwordlessRegistration'] == null) {
            throw new runtime.RequiredError(
                'passwordlessRegistration',
                'Required parameter "passwordlessRegistration" was null or undefined when calling djRestAuthRegistrationCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/registration/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordlessRegistrationToJSON(requestParameters['passwordlessRegistration']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     */
    async djRestAuthRegistrationCreate(requestParameters: DjRestAuthRegistrationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.djRestAuthRegistrationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async djRestAuthRegistrationResendEmailCreateRaw(requestParameters: DjRestAuthRegistrationResendEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters['resendEmailVerification'] == null) {
            throw new runtime.RequiredError(
                'resendEmailVerification',
                'Required parameter "resendEmailVerification" was null or undefined when calling djRestAuthRegistrationResendEmailCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/registration/resend-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendEmailVerificationToJSON(requestParameters['resendEmailVerification']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     */
    async djRestAuthRegistrationResendEmailCreate(requestParameters: DjRestAuthRegistrationResendEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthRegistrationResendEmailCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async djRestAuthRegistrationVerifyEmailCreateRaw(requestParameters: DjRestAuthRegistrationVerifyEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestAuthDetail>> {
        if (requestParameters['verifyEmail'] == null) {
            throw new runtime.RequiredError(
                'verifyEmail',
                'Required parameter "verifyEmail" was null or undefined when calling djRestAuthRegistrationVerifyEmailCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/registration/verify-email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailToJSON(requestParameters['verifyEmail']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestAuthDetailFromJSON(jsonValue));
    }

    /**
     */
    async djRestAuthRegistrationVerifyEmailCreate(requestParameters: DjRestAuthRegistrationVerifyEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestAuthDetail> {
        const response = await this.djRestAuthRegistrationVerifyEmailCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserPartialUpdateRaw(requestParameters: DjRestAuthUserPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/user/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCustomUserToJSON(requestParameters['patchedCustomUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomUserFromJSON(jsonValue));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserPartialUpdate(requestParameters: DjRestAuthUserPartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser> {
        const response = await this.djRestAuthUserPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/user/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomUserFromJSON(jsonValue));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser> {
        const response = await this.djRestAuthUserRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserUpdateRaw(requestParameters: DjRestAuthUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomUser>> {
        if (requestParameters['customUser'] == null) {
            throw new runtime.RequiredError(
                'customUser',
                'Required parameter "customUser" was null or undefined when calling djRestAuthUserUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dj-rest-auth/user/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomUserToJSON(requestParameters['customUser']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomUserFromJSON(jsonValue));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     */
    async djRestAuthUserUpdate(requestParameters: DjRestAuthUserUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomUser> {
        const response = await this.djRestAuthUserUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
