/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserSignupStats,
} from '../models/index';
import {
    UserSignupStatsFromJSON,
    UserSignupStatsToJSON,
} from '../models/index';

/**
 * DashboardApi - interface
 * 
 * @export
 * @interface DashboardApiInterface
 */
export interface DashboardApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    dashboardApiUserSignupsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSignupStats>>;

    /**
     */
    dashboardApiUserSignupsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSignupStats>;

}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI implements DashboardApiInterface {

    /**
     */
    async dashboardApiUserSignupsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSignupStats>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/dashboard/api/user-signups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSignupStatsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardApiUserSignupsRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSignupStats> {
        const response = await this.dashboardApiUserSignupsRetrieveRaw(initOverrides);
        return await response.value();
    }

}
